.select {
    --border-width: 1px;
    --border-color: #ccc;
    --border-radius: 20px;
    --bg-hover-color: #eee;
    --transition: .5s ease-in-out
}

.select__details {
    border: var(--border-width) solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 10px 20px;
    cursor: pointer;
}

.select__details:hover {
    --border-color: #eee;
}

.select__details[open] .select__details__summary::first-line {
    font-weight: bold;
}

.select__details__summary {
    margin-block: 5px;
}

.select__details__summary span {
    --border-width: 2px;
    display: inline-block;
    border-radius: calc(var(--border-radius)/2);
    border: var(--border-width) solid var(--border-color);
    padding: 5px;
    position: relative;
}

.select__list {
    list-style: none;
    border: var(--border-width) solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 10px 20px;
}

.select__list li {
    margin-block: 2px;
}

.select__list li:hover {
    background-color: var(--bg-hover-color);
    transition: var(--transition)
}

.select__list li[aria-selected=true] {
    background-color: var(--bg-hover-color);
    font-weight: bold;
}

.select__times {
    all: unset;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: -17px;
    right: -5px;
}

.select__times:focus {
    outline: 2px solid;
}

.select__times:hover {
    --bg-hover-color: #aaa;
    color: var(--bg-hover-color)
}

.select__delete-all-times {
    background-color: transparent;
    border: var(--border-width) solid var(--bg-hover-color);
    border-radius: var(--border-radius);
    font-weight: bold;
    cursor: pointer;
}