button {
    border-radius: 10px;
    padding: 6px 12px;
    border: none;
    transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1)
}

button:hover {
    opacity: .8;
}

button:active {
    opacity: 1;
}

@media only screen and (max-width:480px) {
    button {
        margin-bottom: 5px;
    }
}